export const EXPERT_TAB_LABEL = 'Experts';
export const PEOPLE_TAB_LABEL = 'People';
export const COMPNAY_TAB_LABEL = 'People';
export const STACKOVERFLOW_TAB_LABEL= 'Stackoverflow';
export const BOTH_PEOPLE_STACKOVERFLOW_TAB_LABELL= 'Stackoverflow';
export const LLM_USAGE_INFO_TO_USER = "Directory Services utilize OpenAI's Large Language Model within the Einstein LLM Gateway for specific search functionalities";

export const ADMIN = 'Admin';
export const SALESFORCE_EMAIL_EXPRESSION = /^([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@(salesforce\.com)+$/;
export const LOAD_DATA_FROM_CACHE = true;
// export const CLIENT_ID = '335131290718-oq0fi2sf0jn8ndmmpmqkmb1stt2f7knu.apps.googleusercontent.com';
export const CLIENT_ID = '654774779440-qcjshugtdkavr55canjfos908r1tem09.apps.googleusercontent.com';
